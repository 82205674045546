var exports = {};
exports.__esModule = true;
exports.default = void 0;

class OneKeyMap {
  constructor() {
    this.set = void 0;
    this.get = void 0;
    let key, val;

    this.get = k => k === key ? val : void 0;

    this.set = (k, v) => {
      key = k;
      val = v;
    };
  }

}

var _default = OneKeyMap;
exports.default = _default;
export default exports;
export const __esModule = exports.__esModule;